import jQuery from 'jquery';

const selectContactElementId = 'register_event_attendee_contactId';
const selectedContactElementId = 'select2-register_event_attendee_contactId-container';
const contactFieldsElementId = 'contact-fields';

let previousSelectedContactValue = null;

function hideOrShowContactFields() {
    const contactFields = $(`#${contactFieldsElementId}`);

    const selectedContactIdHTMLElement = document.getElementById(selectedContactElementId);

    if (null === selectedContactIdHTMLElement) {
        // This is the case in the edit form
        return contactFields.hide();
    }

    const selectedContact = selectedContactIdHTMLElement.getAttribute('title');
    const selectedContactChanged = selectedContact !== previousSelectedContactValue;

    if (!selectedContactChanged) {
        return;
    }

    if (null === selectedContact) {
        contactFields.show();
    } else {
        contactFields.hide();
    }

    previousSelectedContactValue = selectedContact;
}

jQuery(document).ready(function () {
    $(`#${selectContactElementId}`).on('change', hideOrShowContactFields);
    hideOrShowContactFields();
});
