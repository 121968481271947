import jQuery from 'jquery';

function sendHeight() {
    const mainContent = document.querySelector('.page-container');

    if (!mainContent) {
        return;
    }

    const height = mainContent.scrollHeight;

    window.top.postMessage(
        {
            type: 'resizeIframe',
            height: height,
        },
        ADMIN_ORIGIN,
    );
}

sendHeight();

window.addEventListener('resize', sendHeight);
document.addEventListener('DOMContentLoaded', sendHeight);
jQuery(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function () {
    sendHeight();
});
